import React from "react";

const ContactPageTemplate = () => (
  <iframe
    src="https://docs.google.com/forms/d/e/1FAIpQLSefgIKI6hoaSxD-Htv6mUglnV3KZa6V8IHBjsn76yhvP9VsWg/viewform?embedded=true"
    width="100%"
    height="1600"
    frameBorder="0"
    marginHeight="0"
    marginWidth="0"
  >
    読み込んでいます…
  </iframe>
);

ContactPageTemplate.propTypes = {};

export default ContactPageTemplate;
