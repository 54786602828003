import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import Header from "../components/Hero";
import { graphql } from "gatsby";
import ContactPageTemplate from "../components/ContactPageTemplate";
import Layout from "../components/Layout";
import SectionGradient from "../components/SectionGradient";

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const { childImageSharp } = data.file;

  return (
    <Layout>
      <Helmet>
        <title>{frontmatter.meta_title}</title>
        <meta name="description" content={frontmatter.meta_description} />
      </Helmet>
      <Header
        title={frontmatter.title}
        background_image={childImageSharp.fluid}
      />
      <SectionGradient>
        <ContactPageTemplate />
      </SectionGradient>
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        meta_title
        meta_description
      }
    }
    file(relativePath: { eq: "marina-loucaides-VmziYgps3T.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
